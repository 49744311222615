import {
  IHoldingsService,
  BuySellItemRequest,
  TransferRequest,
  FixProfitLossRequest,
  DepositWithdrawRequest,
  EditHoldingRequest,
  CreateHoldingRequest,
  HoldingResponse,
  Holding,
  ChangeTickerRequest
} from 'Services/holdings'
import i18next from 'config/i18n'
import { DemoOperationWarning } from 'Demo/demo-constants'

export class DemoHoldingsService implements IHoldingsService {
  getHoldings(token: string): any {
    return import('./holdings.json')
      .then(holdings => holdings.default.map(holding => ({
        ...holding,
        asset: {
          name: i18next.t(holding.asset.name, { ns: 'demo' }),
          company: i18next.t(holding.asset.company, { ns: 'demo' })
        },
        category: i18next.t(holding.category, { ns: 'demo' }),
        tags: holding.tags.map(tag => i18next.t(tag, { ns: 'demo' }))
      })))
      .then(holdings => holdings.map((holding: unknown) => new Holding(holding as HoldingResponse)))
  }

  depositWithdraw(key: string, token: string, payload: DepositWithdrawRequest): any {
    return Promise.reject(DemoOperationWarning)
  }

  createHolding(token: string, payload: CreateHoldingRequest): any {
    return Promise.reject(DemoOperationWarning)
  }

  archiveHolding(key: string, token: string): any {
    return Promise.reject(DemoOperationWarning)
  }

  deleteHolding(key: string, token: string): any {
    return Promise.reject(DemoOperationWarning)
  }

  editHolding(key: string, token: string, payload: EditHoldingRequest): any {
    return Promise.reject(DemoOperationWarning)
  }

  fixProfitAndLoss(key: string, token: string, payload: FixProfitLossRequest): any {
    return Promise.reject(DemoOperationWarning)
  }

  getCurrencyExchangeRate(token: string, from: string, to: string): any {
    if (from === to) {
      return Promise.resolve(1)
    }

    return Promise.resolve(2)
  }

  getHolding(key: string, token: string): any {
    return import('./holdings.json').then(holdings => holdings.default.find(holding => holding.key === key))
  }

  getSummary(token: string): any {
    return import('./summary.json').then(summary => summary.default)
  }

  pinHolding(key: string, token: string): any {
    return Promise.resolve()
  }

  transfer(token: string, payload: TransferRequest): any {
    return Promise.reject(DemoOperationWarning)
  }

  buySellItem(key: string, token: string, payload: BuySellItemRequest): any {
    return Promise.reject(DemoOperationWarning)
  }

  changeTicker(key: string, token: string, payload: ChangeTickerRequest): any {
    return Promise.resolve()
  }
}
