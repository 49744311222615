import React from 'react'
import { ButtonGroup, Button, Popper, Grow, Stack, Paper, ClickAwayListener, MenuList, MenuItem } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { TextPrimary, SubTextSecondary2 } from 'Shared/components'

type SplitButtonOption = {
  label: string
  onClick: () => void
  description?: string
  disabled?: boolean
}

interface Props {
  options: SplitButtonOption[]
  disabled?: boolean
}

export const SplitButton: React.FC<Props> = ({ options, disabled }) => {
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const [ popperOpened, setPopperOpened ] = React.useState(false)
  const [ selectedOptionIndex, setSelectedOptionIndex ] = React.useState(0)

  const handleTogglePopper = () => {
    setPopperOpened(prevOpen => !prevOpen)
  }

  const handleClosePopper = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setPopperOpened(false)
  }

  const handleMenuItemClick = (index: number) => {
    setSelectedOptionIndex(index)
    setPopperOpened(false)
  }

  return (
    <>
      <ButtonGroup variant="contained" ref={anchorRef}>
        <Button
          size="small"
          onClick={options[selectedOptionIndex].onClick}
          disabled={disabled || options[selectedOptionIndex].disabled}
        >
          {options[ selectedOptionIndex ].label}
        </Button>

        <Button
          size="small"
          aria-controls={popperOpened ? 'split-button-menu' : undefined}
          aria-expanded={popperOpened ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleTogglePopper}
          disabled={disabled}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Popper
        sx={{ zIndex: 1 }}
        open={popperOpened}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClosePopper}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      disabled={option.disabled}
                      selected={index === selectedOptionIndex}
                      onClick={() => handleMenuItemClick(index)}
                      sx={{ maxWidth: 300 }}
                    >
                      <Stack sx={{ width: '100%', textWrap: 'wrap' }}>
                        <TextPrimary>{option.label}</TextPrimary>
                        {option.description && <SubTextSecondary2>{option.description}</SubTextSecondary2>}
                      </Stack>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
