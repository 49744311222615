import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { container, Service } from 'Services/container'
import { IHoldingsService, Summary, Holding } from 'Services/holdings'
import { IPlaidService, PlaidConnection } from 'Services/plaid'
import type { ITypedActionWithToken, IActionWithToken } from 'Shared/types'
import { snackbarActionCreators } from 'Snackbar'
import { HoldingActions } from './holdings-actions'
import { HoldingsActionTypes } from './holdings-constants'
import { HoldingSelectors } from './holdings-selectors'
import { ArchiveHoldingSpecification } from './holdings-specifications'

export function* getHoldings({ token }: IActionWithToken) {
  const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)

  try {
    const response: Holding[] = yield call(holdingsService.getHoldings, token)
    yield put(HoldingActions.getHoldingsSuccess(response))
  } catch (error) {
    yield put(HoldingActions.getHoldingsFailure())
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.table.loading-failure'))
  }
}

export function* getSummary({ token }: IActionWithToken) {
  const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)

  try {
    const response: Summary = yield call(holdingsService.getSummary, token)
    yield put(HoldingActions.getSummarySuccess(response))
  } catch (error) {
    yield put(HoldingActions.getSummaryFailure())
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.summary.loading-failure'))
  }
}

function* archiveHolding({ payload, token }: ITypedActionWithToken<string>) {
  try {
    const holdingToArchive: Holding | null = yield select(HoldingSelectors.holdingSelector, payload)

    if (!holdingToArchive) { return }

    const specification = new ArchiveHoldingSpecification(holdingToArchive)
    const specificationResult = specification.verify()

    if (!specificationResult.isValid) {
      yield put(snackbarActionCreators.enqueueWarningSnackbar(specificationResult.errorKey))
      return
    }

    const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)
    yield call(holdingsService.archiveHolding, payload, token)

    yield put(HoldingActions.getHoldings(token))
    yield put(HoldingActions.getSummary(token))
    yield put(snackbarActionCreators.enqueueSuccessSnackbar('holdings.manage.archive-success', { holding: holdingToArchive.asset.name }))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.archive-failure'))
  }
}

function* deleteHolding({ payload, token }: ITypedActionWithToken<string>) {
  try {
    const holdingToDelete: Holding | null = yield select(HoldingSelectors.holdingSelector, payload)

    if (!holdingToDelete) { return }

    const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)
    yield call(holdingsService.deleteHolding, payload, token)

    yield put(HoldingActions.getHoldings(token))
    yield put(HoldingActions.getSummary(token))
    yield put(snackbarActionCreators.enqueueSuccessSnackbar('holdings.manage.delete-success', { holding: holdingToDelete.asset.name }))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.delete-failure'))
  }
}

function* pinHolding({ payload, token }: ITypedActionWithToken<string>) {
  try {
    const holdingsService = container.resolve<IHoldingsService>(Service.HoldingsService)
    yield call(holdingsService.pinHolding, payload, token)
    const pinDate = new Date().toISOString()
    const data = { key: payload, pinDate }
    yield put(HoldingActions.pinHoldingSuccess(data))
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.manage.pin-failure'))
  }
}

function* createPlaidConnection({ payload, token }: ITypedActionWithToken<string>) {
  const plaidService = container.resolve<IPlaidService>(Service.PlaidService)

  try {
    yield put(HoldingActions.drawers.connectAccount.close())
    yield put(HoldingActions.setBackdrop(true))

    const connectionKey: string = yield call(plaidService.createConnection, token, payload)
    const connection: PlaidConnection = yield call(plaidService.getConnection, token, connectionKey)

    yield put(HoldingActions.getHoldings(token))
    yield put(HoldingActions.getSummary(token))

    yield put(snackbarActionCreators.enqueueSuccessSnackbar('holdings.connection.plaid.connection-success', { account: connection.institutionName }))

    if (connection.finstrumentKeys.length === 1) {
      const [ finstrumentKey ] = connection.finstrumentKeys
      yield put(HoldingActions.drawers.viewHolding.open(finstrumentKey))
    }
  } catch (error) {
    yield put(snackbarActionCreators.enqueueErrorSnackbar('holdings.connection.plaid.connection-failure'))
  } finally {
    yield put(HoldingActions.setBackdrop(false))
  }
}

export function* holdingsSaga() {
  yield all([
    takeEvery(HoldingsActionTypes.GET_HOLDINGS, getHoldings),
    takeEvery(HoldingsActionTypes.GET_SUMMARY, getSummary),
    takeEvery(HoldingsActionTypes.ARCHIVE_HOLDING, archiveHolding),
    takeEvery(HoldingsActionTypes.DELETE_HOLDING, deleteHolding),
    takeEvery(HoldingsActionTypes.TOGGLE_PIN_HOLDING, pinHolding),
    takeEvery(HoldingsActionTypes.CREATE_PLAID_CONNECTION, createPlaidConnection),
  ])
}
