import { Stack, styled } from '@mui/material'
import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { RouteResolver as AdvisorPortalResolver } from 'AdvisorPortal/RouteResolver'
import { AdvisorPortalDrawers } from 'AdvisorPortal/components/AdvisorPortalDrawers'
import { AppBar as AdvisorPortalAppBar } from 'AdvisorPortal/components/AppBar'
import { AppFooter, LandingFooter, UserGuide } from 'App'
import { AdvisorLandingNavigationBar, AppBar, LandingNavigationBar, MinimalisticNavigationBar, PublicPageNavigationBar } from 'App/NavigationBar'
import { PaymentIssuesNotification } from 'App/Premium/PaymentIssuesNotification'
import { ActivationSuccessful, NoMatch, PaymentFailed, PaymentSuccessful, UnderMaintenance } from 'App/StaticPages'
import { ViewMode, ViewModeProvider } from 'App/ViewMode'
import { DemoNotification } from 'Demo'
import { AppBar as DemoAppBar } from 'Demo/AppBar'
import { Pricing } from 'Pricing'
import { ApplicationLoadingOverlay } from 'Shared/components'
import { Routes } from 'Shared/constants'
import { DesktopHeaderHeight, MobileHeaderHeight } from 'Shared/constants/spacing'
import { DispatchSharingKey } from 'Sharing'
import { AuthenticationGuard } from './AuthenticationGuard'
import { ProtectedRoute } from './ProtectedRoute'

const LandingPage = lazy(() => import('App/Landings/LandingPage/LandingPage'))
const AdvisorLandingPage = lazy(() => import('App/Landings/AdvisorLandingPage/AdvisorLandingPage'))
const AnalyticsPage = lazy(() => import('Analytics/components/Analytics.page'))
const HoldingsPage = lazy(() => import('Holdings/components/Holdings.page'))
const PredictionPage = lazy(() => import('Prediction/components/Prediction.page'))
const TransactionsPage = lazy(() => import('Transactions/components/Transactions.page'))
const AdvisorPortalPage = lazy(() => import('AdvisorPortal/components/AdvisorPortal.page'))
const PublicPrediction = lazy(() => import('Prediction/components/PublicPrediction.page'))

const AppWrapper = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  flexDirection: 'column',
  minWidth: 0,
  marginTop: DesktopHeaderHeight,
  [theme.breakpoints.down('sm')]: {
    // AppBar height + AppBarBreadcrumbs height
    marginTop: 56 + 56
  },
}))

const StaticPageWrapper = styled(Stack)(({ theme }) => ({
  flexGrow: 1,
  flexDirection: 'column',
  minWidth: 0,
  marginTop: DesktopHeaderHeight,
  [theme.breakpoints.down('mobileLarge')]: {
    marginTop: MobileHeaderHeight
  }
}))

export const RouterBase: React.FC = () => (
  <Switch>
    <Route exact path={Routes.HOME}>
      <Suspense fallback={<ApplicationLoadingOverlay />}>
        <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
          <LandingNavigationBar />
          <StaticPageWrapper>
            <LandingPage />
            <LandingFooter />
          </StaticPageWrapper>
        </ViewModeProvider>
      </Suspense>
    </Route>

    <Route exact path={Routes.ADVISOR_HOME}>
      <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
        <AdvisorLandingNavigationBar />
        <StaticPageWrapper>
          <AdvisorLandingPage />
          <LandingFooter />
        </StaticPageWrapper>
      </ViewModeProvider>
    </Route>

    <Route exact path={Routes.UNDER_MAINTENANCE}>
      <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
        <MinimalisticNavigationBar />
        <StaticPageWrapper>
          <UnderMaintenance />
          <AppFooter />
        </StaticPageWrapper>
      </ViewModeProvider>
    </Route>

    <Route exact path={Routes.PAYMENT_SUCCESS}>
      <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
        <MinimalisticNavigationBar />
        <StaticPageWrapper>
          <PaymentSuccessful />
          <AppFooter />
        </StaticPageWrapper>
      </ViewModeProvider>
    </Route>

    <Route exact path={Routes.PAYMENT_FAILED}>
      <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
        <MinimalisticNavigationBar />
        <StaticPageWrapper>
          <PaymentFailed />
          <AppFooter />
        </StaticPageWrapper>
      </ViewModeProvider>
    </Route>

    <Route exact path={Routes.ACTIVATION_SUCCESS}>
      <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
        <MinimalisticNavigationBar />
        <StaticPageWrapper>
          <ActivationSuccessful />
          <AppFooter />
        </StaticPageWrapper>
      </ViewModeProvider>
    </Route>

    <Route path={Routes.PUBLIC_FORECAST}>
      <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
        <PublicPageNavigationBar />
        <StaticPageWrapper>
          <Suspense fallback={<ApplicationLoadingOverlay />}>
            <PublicPrediction />
            <LandingFooter />
          </Suspense>
        </StaticPageWrapper>
      </ViewModeProvider>
    </Route>

    <Route path={Routes.DEMO}>
      <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
        <DemoAppBar />
        <AppWrapper>
          <DemoNotification />
          <Suspense fallback={<ApplicationLoadingOverlay />}>
            <Switch>
              <Route exact path={Routes.DEMO_HOLDINGS} component={HoldingsPage} />
              <Route exact path={Routes.DEMO_TRANSACTIONS} component={TransactionsPage} />
              <Route exact path={Routes.DEMO_FORECAST} component={PredictionPage} />
              <Route path={Routes.DEMO_ANALYTICS} component={AnalyticsPage} />
              <Route exact path="*">
                <Redirect to={Routes.NO_MATCH} />
              </Route>
            </Switch>
            <AppFooter />
          </Suspense>
        </AppWrapper>
        <UserGuide />
      </ViewModeProvider>
    </Route>

    <Route path={Routes.PROFILE}>
      <ViewModeProvider value={{ mode: ViewMode.DefaultUser }}>
        <AppBar />
        <AppWrapper>
          <PaymentIssuesNotification />
          <Suspense fallback={<ApplicationLoadingOverlay />}>
            <Switch>
              <ProtectedRoute exact path={Routes.PRICING} component={Pricing} />
              <ProtectedRoute exact path={Routes.HOLDINGS} component={HoldingsPage} />
              <ProtectedRoute exact path={Routes.TRANSACTIONS} component={TransactionsPage} />
              <ProtectedRoute exact path={Routes.FORECAST} component={PredictionPage} />
              <ProtectedRoute path={Routes.ANALYTICS} component={AnalyticsPage} />
              <Route exact path={`${Routes.PROFILE}/*`}>
                <Redirect to={Routes.NO_MATCH} />
              </Route>
            </Switch>
          </Suspense>
          <AppFooter />
        </AppWrapper>
        <UserGuide />
      </ViewModeProvider>
    </Route>

    <Route path={Routes.ADVISOR_PORTAL}>
      <AuthenticationGuard>
        <AdvisorPortalResolver>
          <ViewModeProvider value={{ mode: ViewMode.Advisor }}>
            <AdvisorPortalAppBar />
            <AppWrapper>
              <Suspense fallback={<ApplicationLoadingOverlay />}>
                <Switch>
                  <ProtectedRoute exact path={Routes.ADVISOR_PORTAL} component={AdvisorPortalPage} />
                  <Route path={Routes.ADVISOR_PORTAL_SHARING}>
                    <DispatchSharingKey>
                      <Switch>
                        <ProtectedRoute exact path={Routes.ADVISOR_PORTAL_SHARING_HOLDINGS} component={HoldingsPage} />
                        <ProtectedRoute exact path={Routes.ADVISOR_PORTAL_SHARING_TRANSACTIONS} component={TransactionsPage} />
                        <ProtectedRoute exact path={Routes.ADVISOR_PORTAL_SHARING_FORECASTING} component={PredictionPage} />
                        <ProtectedRoute path={Routes.ADVISOR_PORTAL_SHARING_ANALYTICS} component={AnalyticsPage} />
                        <Route path={`${Routes.ADVISOR_PORTAL}/*`}>
                          <Redirect to={Routes.NO_MATCH} />
                        </Route>
                      </Switch>
                    </DispatchSharingKey>
                  </Route>
                  <Route exact path={`${Routes.ADVISOR_PORTAL}/*`}>
                    <Redirect to={Routes.NO_MATCH} />
                  </Route>
                </Switch>
              </Suspense>
              <AppFooter />
            </AppWrapper>
            <AdvisorPortalDrawers />
          </ViewModeProvider>
        </AdvisorPortalResolver>
      </AuthenticationGuard>
    </Route>

    <Route path="*" component={NoMatch} />
    <Route path={Routes.NO_MATCH} component={NoMatch} />
  </Switch>
)

export const Router = React.memo(RouterBase)
