// The order is important for the NavBar
export const Routes = {
  HOME: '/',
  PROFILE: '/profile',
  HOLDINGS: '/profile/holdings',
  TRANSACTIONS: '/profile/transactions',
  ANALYTICS: '/profile/analytics',
  ANALYTICS_DYNAMICS: '/dynamics',
  ANALYTICS_MARKET: '/market',
  ANALYTICS_ALLOCATION: '/allocation',
  ANALYTICS_FAVORITES: '/favorites',
  FORECAST: '/profile/forecast',
  PRICING: '/profile/pricing',
  ADVISOR_HOME: '/advisor-home',
  ADVISOR_PORTAL: '/advisor-portal',
  ADVISOR_PORTAL_SHARING: '/advisor-portal/sharing/:sharingKey',
  ADVISOR_PORTAL_SHARING_HOLDINGS: '/advisor-portal/sharing/:sharingKey/holdings',
  ADVISOR_PORTAL_SHARING_TRANSACTIONS: '/advisor-portal/sharing/:sharingKey/transactions',
  ADVISOR_PORTAL_SHARING_ANALYTICS: '/advisor-portal/sharing/:sharingKey/analytics',
  ADVISOR_PORTAL_SHARING_FORECASTING: '/advisor-portal/sharing/:sharingKey/forecasting',
  DEMO: '/demo',
  DEMO_HOLDINGS: '/demo/holdings',
  DEMO_TRANSACTIONS: '/demo/transactions',
  DEMO_ANALYTICS: '/demo/analytics',
  DEMO_FORECAST: '/demo/forecast',
  UNDER_MAINTENANCE: '/under-maintenance',
  PAYMENT_SUCCESS: '/payment-success',
  PAYMENT_FAILED: '/payment-failed',
  ACTIVATION_SUCCESS: '/activation-success',
  PUBLIC_FORECAST: '/forecast',
  NO_MATCH: '/no-match'
}

export const RouteNames = {
  [Routes.HOLDINGS]: 'app.navbar.routes.holdings',
  [Routes.TRANSACTIONS]: 'app.navbar.routes.transactions',
  [Routes.ANALYTICS]: 'app.navbar.routes.analytics',
  [Routes.FORECAST]: 'app.navbar.routes.prediction',
  [Routes.DEMO_HOLDINGS]: 'app.navbar.routes.holdings',
  [Routes.DEMO_TRANSACTIONS]: 'app.navbar.routes.transactions',
  [Routes.DEMO_ANALYTICS]: 'app.navbar.routes.analytics',
  [Routes.DEMO_FORECAST]: 'app.navbar.routes.prediction',
  [Routes.PRICING]: 'app.navbar.routes.pricing',
  [Routes.ADVISOR_PORTAL_SHARING_HOLDINGS]: 'app.navbar.routes.holdings',
  [Routes.ADVISOR_PORTAL_SHARING_TRANSACTIONS]: 'app.navbar.routes.transactions',
  [Routes.ADVISOR_PORTAL_SHARING_ANALYTICS]: 'app.navbar.routes.analytics',
  [Routes.ADVISOR_PORTAL_SHARING_FORECASTING]: 'app.navbar.routes.prediction',
  [Routes.ADVISOR_PORTAL]: 'app.navbar.routes.advisorPortal',
}

export const RouteBuilder = {
  buildAdvisorPortalSharingHoldings: (sharingKey: string) => `/advisor-portal/sharing/${sharingKey}/holdings`,
  buildAdvisorPortalSharingTransactions: (sharingKey: string) => `/advisor-portal/sharing/${sharingKey}/transactions`,
  buildAdvisorPortalSharingAnalytics: (sharingKey: string) => `/advisor-portal/sharing/${sharingKey}/analytics`,
  buildAdvisorPortalSharingForecasting: (sharingKey: string) => `/advisor-portal/sharing/${sharingKey}/forecasting`,
}

export const ExternalLinks = {
  CONTACT: {
    TELEGRAM_NEWS: 'https://t.me/firekitnews',
    INSTAGRAM: 'https://www.instagram.com/firekit.space',
    BMC: 'https://www.buymeacoffee.com/firekit',
    EMAIL_US: 'mailto:support@firekit.space',
  },
  LEGAL: {
    PRIVACY_POLICY: (lng: string) => `${process.env.REACT_APP_BLOB_HOST}/Privacy Policy-${lng}.pdf`,
    TERMS_OF_USE: (lng: string) => `${process.env.REACT_APP_BLOB_HOST}/Terms Of Use-${lng}.pdf`,
    COOKIE_POLICY: (lng: string) => `${process.env.REACT_APP_BLOB_HOST}/Cookie Policy-${lng}.pdf`,
    PUBLIC_OFFER_AGREEMENT: (lng: string) => `${process.env.REACT_APP_BLOB_HOST}/Public Offer Agreement-${lng}.pdf`,
    SECURITY_DOCUMENT: (lng: string) => `${process.env.REACT_APP_BLOB_HOST}/Security-document-${lng}.pdf`,
  },
  INFO: {
    INFLATION_TOOLTIP: 'https://tradingeconomics.com/united-states/inflation-cpi',
    LIFE_EXPECTANCY: 'https://www.worldometers.info/demographics/life-expectancy',
    TWR: 'https://www.investopedia.com/terms/t/time-weightedror.asp',
    XIRR_DOCS_EN: 'https://support.microsoft.com/en-us/office/xirr-function-de1242ec-6477-445b-b11b-a303ad9adc9d',
    XIRR_DOCS_UK: 'https://support.microsoft.com/uk-ua/office/xirr-функція-xirr-de1242ec-6477-445b-b11b-a303ad9adc9d',
    REALIZED: 'https://www.investopedia.com/terms/r/realizedprofit.asp',
    UNREALIZED: 'https://www.investopedia.com/terms/u/unrealizedgain.asp',
    YOUTUBE_DEMO_CRYPTO: 'https://youtu.be/915J9UwsVK4',
    YOUTUBE_DEMO: {
      VIDEO: 'https://youtu.be/rkpRqkfBVp4',
      T_00_00: 'https://www.youtube.com/watch?v=rkpRqkfBVp4&t=0s',
      T_01_14: 'https://www.youtube.com/watch?v=rkpRqkfBVp4&t=74s',
      T_04_43: 'https://www.youtube.com/watch?v=rkpRqkfBVp4&t=283s',
      T_05_19: 'https://www.youtube.com/watch?v=rkpRqkfBVp4&t=319s',
      T_07_53: 'https://www.youtube.com/watch?v=rkpRqkfBVp4&t=473s',
      T_18_06: 'https://www.youtube.com/watch?v=rkpRqkfBVp4&t=1086s',
      T_19_46: 'https://www.youtube.com/watch?v=rkpRqkfBVp4&t=1186s',
      T_24_37: 'https://www.youtube.com/watch?v=rkpRqkfBVp4&t=1477s',
    },
    REGION_EXPOSURE: 'https://admainnew.morningstar.com/directhelp/Glossary/Portfolio/Regional_Exposure.htm',
    CAGR_INFO: 'https://www.investopedia.com/terms/c/cagr.asp',
  },
  WIKI: {
    HOW_TO_USE_UK: 'https://share.nuclino.com/p/-FIREkit-oodoTQhT8iaJbDmC_dToae',
    HOW_TO_USE_EN: 'https://share.nuclino.com/p/How-it-works-ulwnnlRSYmUn5MlEtyyRwO',
    FAQ_UK: 'https://share.nuclino.com/p/--tqEg5reJKZ2iuIpecCNKyp',
    FAQ_EN: 'https://share.nuclino.com/p/FAQ-3tkq-IJtcm-ONzykwHDxHt',
    LIMITS_UK: 'https://share.nuclino.com/p/--oNM5Kixv0e24s8Q_v2MpkA',
    LIMITS_EN: 'https://share.nuclino.com/p/FIREkit-free-plan-limitations-27-3wPt7jRYxH_10Gf2wlH',
    FREE_EDUCATION_ACCESS_UK: 'https://share.nuclino.com/p/--Fpxh9ZCJGWjrn11LWRAs5M',
    FREE_EDUCATION_ACCESS_EN: 'https://share.nuclino.com/p/Free-access-for-educational-institutions-and-courses-sbuLu4XnbEc08sPKIA6Jq8',
  },
  CHANGELOG: {
    UK: 'https://share.nuclino.com/p/FIREkit--kt2EDpJesgpPvOPPSbZLoH',
    EN: 'https://share.nuclino.com/p/FIREkit-Changelog--dp2gOz-91T4BGZuiU_Wto',
  },
  COME_BACK_ALIVE_UK: 'https://savelife.in.ua/donate/',
  COME_BACK_ALIVE_EN: 'https://savelife.in.ua/en/donate-en/',
  MAILTO: 'mailto:support@firekit.space',
  ADVISORY_BOOK_DEMO: 'https://calendly.com/dmytro_zhykin/firekit-fin-advisory-demo',
  ADVISOR_LANDING: 'https://firekit.space/advisor-home',
  IMPORT: {
    TEMPLATE_UK: 'https://sasharedprodwesteu.blob.core.windows.net/public-files/FIREkit Шаблон імпорту.xlsx',
    TEMPLATE_EN: 'https://sasharedprodwesteu.blob.core.windows.net/public-files/FIREkit Import template.xlsx',
  }
}
